<template>
  <div class="main-content">
    <div class="card user-profile o-hidden mb-30">
      <div class="header-cover" />
      <div class="user-info">
        <img
          class="profile-picture avatar-lg mb-2"
          src="@/assets/images/default.png"
          alt=""
        >
        <p class="m-0 text-24">
          Matches
        </p>
      </div>
      <b-card class="wrapper">
        <matches-full-table
          :items="matchItems"
          :on-item-click="onCareRecipientItemClick"
        />
        <b-form-group
          label="Legenda:"
          label-for="input-1"
          class="col-md-8"
        >
          <div>
            <b-icon
              icon="circle-fill"
              variant="danger"
            /><span class="m-2">Open aanvraag</span>
          </div>
          <div>
            <b-icon
              icon="circle-fill"
              variant="info"
            /><span class="m-2">Gekoppeld</span>
          </div>
          <div>
            <b-icon
              icon="circle-fill"
              variant="success"
            /><span class="m-2">Afgehandeld</span>
          </div>
          <div>
            <b-icon
              icon="circle-fill"
              color="#888"
            /><span class="m-2">Niet in te vullen</span>
          </div>
          <div>
            <b-icon
              icon="circle-fill"
              variant="warning"
            /><span class="m-2">Niet doorgegaan</span>
          </div>
        </b-form-group>
        <b-modal
          id="care-recipient-item-modal"
          size="xl"
          :ok-title="okLabel"
          :cancel-title="cancelLabel"
          title="Hulpvraag"
          @ok="saveCareRecipientItem"
        >
          <template v-slot:modal-footer="{ ok, cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button
              size="sm"
              variant="success"
              @click.prevent="ok()"
            >
              Opslaan
            </b-button>
            <b-button
              v-if="careRecipientItem._id"
              size="sm"
              variant="danger"
              @click="showConfirmRemoveCareRecipientItem"
            >
              Verwijderen
            </b-button>
            <!-- Button with custom close trigger value -->
            <b-button
              size="sm"
              variant="outline-secondary"
              @click.prevent="cancel()"
            >
              Sluiten
            </b-button>
          </template>
          <b-card>
            <b-modal
              id="confirm-remove-carerecipient-item"
              hide-backdrop
              content-class="shadow"
              title="Verwijderen"
            >
              <template v-slot:modal-footer="{ ok, cancel }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent="removeCareRecipientItem"
                >
                  Ja
                </b-button>
                <!-- Button with custom close trigger value -->
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click.prevent="cancel()"
                >
                  Nee
                </b-button>
              </template>
              <p class="my-4">
                Weet u zeker dat u dit item wilt verwijderen?
              </p>
            </b-modal>
            <care-recipient-item :care-recipient-item="careRecipientItem" />
          </b-card>
        </b-modal>
      </b-card>
    </div>
  </div>
</template>
<script>
import { participantsService } from '@/services';
import MatchesFullTable from '@/components/matches-full-table.vue';
import CareRecipientItem from '@/components/carerecipient-item.vue';

export default {
  components: {
    MatchesFullTable,
    CareRecipientItem,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Deelnemers',
  },
  data() {
    return {
      careRecipientItem: {},
      okLabel: 'Opslaan',
      cancelLabel: 'Sluiten',
      matchItems: [],
    };
  },
  created() {
    participantsService.getAllMatches().then((matchItems) => {
      console.log(matchItems);
      this.matchItems = matchItems;
    });
  },
  methods: {
    onCareRecipientItemClick(data) {
      this.careRecipientItem = JSON.parse(JSON.stringify(data.row.request));

      this.$bvModal.show('care-recipient-item-modal');
    },
    showConfirmRemoveCareRecipientItem() {
      this.$bvModal.show('confirm-remove-carerecipient-item');
    },
    removeCareRecipientItem() {
      participantsService
        .removeCareRecipientItem(this.careRecipientItem)
        .then((res) => {
          participantsService
            .getCareRecipientsItems(this.participant)
            .then((careRecipientItems) => {
              this.careRecipientItems = careRecipientItems;
            });
          this.$bvModal.hide('confirm-remove-carerecipient-item');
          this.$bvModal.hide('care-recipient-item-modal');
          this.$bvToast.toast('Verwijderd', {
            title: 'Melding',
            autoHideDelay: 2500,
            appendToast: false,
            variant: 'info',
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: 'Foutmelding',
            autoHideDelay: 2500,
            appendToast: false,
            variant: 'danger',
          });
        });
    },
    saveCareRecipientItem() {
      console.log(this.careRecipientItem);
      participantsService
        .saveCareRecipientItem(this.careRecipientItem)
        .then((res) => {
          participantsService.getAllCareRecipients().then((careRecipientItems) => {
            this.careRecipientItems = careRecipientItems;
          });
          this.$bvToast.toast('Opgeslagen', {
            title: 'Melding',
            autoHideDelay: 2500,
            appendToast: false,
            variant: 'info',
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: 'Foutmelding',
            autoHideDelay: 2500,
            appendToast: false,
            variant: 'danger',
          });
        });
    },
    showConfirmRemoveCaregiverItem() {
      this.$bvModal.show('confirm-remove-caregiver-item');
    },
    removeCaregiverItem() {
      participantsService
        .removeCaregiverItem(this.caregiverItem)
        .then((res) => {
          participantsService.getAllCareRecipients().then((careRecipientItems) => {
            this.careRecipientItems = careRecipientItems;
          });
          this.$bvModal.hide('confirm-remove-caregiver-item');
          this.$bvModal.hide('caregiver-item-modal');
          this.$bvToast.toast('Verwijderd', {
            title: 'Melding',
            autoHideDelay: 2500,
            appendToast: false,
            variant: 'info',
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: 'Foutmelding',
            autoHideDelay: 2500,
            appendToast: false,
            variant: 'danger',
          });
        });
    },
  },
};
</script>
<style></style>
